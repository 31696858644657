import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Home({ data }) {
  return (
    <Layout>
      <div>
        <h1>List of restaurants</h1>
        <h4>{data.allRestaurant.nodes.length} restaurants</h4>
        {data.allRestaurant.nodes.map(node => (
          <div key={node.id}>
            <Link to={node.path}>
              <h3> {node.name.de} </h3>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query RestaurantList {
    allRestaurant {
      nodes {
        id
        path
        name {
          de
        }
      }
    }
  }
`
